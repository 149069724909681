// WithRealTimeStatus.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

class WithRealTimeStatus extends React.PureComponent {
  subscriptionReference = `WithRealTimeStatus-${uuidv4()}`;
  nextTick = null;

  render() {
    const { isOnline, replyPrice, isLiveStreaming, children } = this.props;
    return children({ isOnline, replyPrice, isLiveStreaming });
  }

  toggleSubscribe = ({ userId, shouldSubscribe }) => {
    const { toggleSubscribeOnlineStatus } = this.props;
    if (userId) {
      toggleSubscribeOnlineStatus({
        userId,
        shouldSubscribe,
        subscriptionReference: this.subscriptionReference,
      });
    }
  };

  componentDidMount() {
    this.nextTick = setTimeout(() => {
      const { userId } = this.props;
      this.toggleSubscribe({ userId, shouldSubscribe: true });
    });
  }

  componentDidUpdate(prevProps) {
    const { userId: prevUserId } = prevProps;
    const { userId } = this.props;

    if (userId !== prevUserId) {
      clearTimeout(this.nextTick);
      this.toggleSubscribe({ userId: prevUserId, shouldSubscribe: false });
      this.nextTick = setTimeout(() => {
        this.toggleSubscribe({ userId, shouldSubscribe: true });
      });
    }
  }

  componentWillUnmount() {
    const { userId } = this.props;
    clearTimeout(this.nextTick);
    this.toggleSubscribe({ userId, shouldSubscribe: false });
  }
}

WithRealTimeStatus.propTypes = {
  toggleSubscribeOnlineStatus: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  userId: PropTypes.string,
  isOnline: PropTypes.bool,
  isLiveStreaming: PropTypes.bool,
  replyPrice: PropTypes.number,
};

WithRealTimeStatus.defaultProps = {
  isOnline: false,
  isLiveStreaming: false,
  replyPrice: null,
};

export default WithRealTimeStatus;
