// LiveStreamCard.jsx
import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import LiveStreamBadge from '../container/LiveStreamBadge.js';

import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import WithRealTimeStatus from '../container/WithRealTimeStatus.js';
import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import HydrationBoundary from '../component/HydrationBoundary.jsx';
import StatefulImage from '../component/StatefulImage.jsx';
import LazyImage, { Image } from '../component/LazyImage.jsx';
import Flip from '../component/Flip.jsx';
import Loading, { LoadingType } from '../component/Loading.jsx';
import DecryptionWrapper from '../container/DecryptionWrapper.js';
import LivestreamCardUserBadges from '../container/LivestreamCardUserBadges.js';
import { isAllAliasesInTargetCategory } from '../resource/feedUtils.js';
import { NULL_FUNCTION, EMPTY_ARRAY } from '../resource/defaults.js';
import { liveStreamCard as liveStreamCardDebug } from '../resource/debug.js';
import { UiQueryWhiteList } from '../resource/feedConstants.js';

import {
  color,
  textColor,
  breakpoint,
  loadingIconOpacity,
} from '../style/variables.js';
import media from '../style/media.js';

import { LIVESTREAM_TYPE_STATUS_BADGE } from '../resource/userStatusIndicatorConstants.js';
import { getLiveSource } from '../resource/playerUtils.js';
import {
  LIVESTREAM_PATH,
  PRESET_PREVIEW,
  privateShowStatus as privateShowStatusConstant,
} from '../resource/liveStreamConstants.js';
import { ButtonId } from '../resource/mixpanel.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { PUBLIC } from '../resource/resourceUrlTypeConstants.js';
import { getUserPicture, MediaAssetFormat } from '../resource/getMediaAsset.js';
import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import withViewportItemTracker from '../resource/withViewportItemTracker.js';

import TextEllipsis from '../style/TextEllipsis.js';

import ViewersIconResource from '../../img/ic-views.svg';
import LoadingImageResource from '../../img/img_loading_white_20.svg';
import CoverPlaceholderImageResource from '../../img/placeholder-bg-logowall.svg';
import IconDeviceSource from '../../img/ic_lovense_badge.svg';
import { withTranslation } from 'react-i18next';
import { getIsOnMobile } from '../resource/getUserAgent.js';

const debugLog = liveStreamCardDebug.extend('log');

const isServer = typeof window === 'undefined';
const DELAY_LOADING_MSEC = 1000; // TODO: remote config

const videoPlayerOption = {
  loop: false,
};
const PLAYER_STATUS = {
  idle: 'idle',
  playing: 'playing',
  loading: 'loading',
  error: 'error',
};

const isMobile = getIsOnMobile();

// Used for these scenario:
// 1. It keeps track of the latest checking ID (make sure that the latest one takes precedence if the timing is close)
// 2. It would/should be reset while unmounting (make sure that it won't carry on while entries or cards other than LiveStreamCard are clicked)
let _latestFetchLiveStreamAvailabilityId;

export class LiveStreamCard extends React.PureComponent {
  timeout = null;
  idleCallback = null;
  fetchRetainedEventsIdleCallback = null;
  state = {
    isHovered: false,
    shouldShowLoadingOnCheckingStream: false,
    playerStatus: this.props.shouldAutoplay
      ? PLAYER_STATUS.loading
      : PLAYER_STATUS.idle,
    videoPlayerEl: null,
  };

  handleOnProfileLinkClick = event => {
    event.preventDefault();

    this.setState({ shouldShowLoadingOnCheckingStream: true });

    const {
      category,
      liveStreamFeed,
      isLiveStreaming,
      isOnUserGrid,
      fetchLiveStreamAvailability,
      userId,
    } = this.props;
    _latestFetchLiveStreamAvailabilityId = uuidv4();

    debugLog('handleOnProfileLinkClick has invoked', {
      _latestFetchLiveStreamAvailabilityId,
      userId,
      isLiveStreaming,
      isOnUserGrid,
      liveStreamFeed,
      category,
      isLiveStreamFeed: isAllAliasesInTargetCategory({
        probeCategory: liveStreamFeed,
        targetCategory: category,
      }),
    });

    fetchLiveStreamAvailability({
      fetchLiveStreamAvailabilityId: _latestFetchLiveStreamAvailabilityId,
    });
  };

  handleInViewport = ({ isIntersecting }) => {
    const { streamId, isLiveStreaming, fetchRetainedEvents } = this.props;

    if (
      isIntersecting &&
      isLiveStreaming == undefined &&
      !this.fetchRetainedEventsIdleCallback
    ) {
      this.fetchRetainedEventsIdleCallback = window.requestIdleCallback(() =>
        fetchRetainedEvents({ channels: [`private-enc-user@${streamId}`] })
      );
    }
  };

  handleOnMouseEnter = () => {
    this.setState(prevState => ({
      isHovered: true,
      playerStatus:
        prevState.playerStatus === PLAYER_STATUS.playing
          ? PLAYER_STATUS.playing
          : PLAYER_STATUS.loading,
    }));
  };

  handleOnMouseLeave = () => {
    this.setState({
      isHovered: false,
      playerStatus: PLAYER_STATUS.idle,
    });
  };

  handlePlayerSetup = () => {
    this.setState({ playerStatus: PLAYER_STATUS.loading });
  };

  handlePlayerError = (error, player) => {
    player.playlist.next();
    this.setState({ playerStatus: PLAYER_STATUS.error });
  };

  handlePlayerPlaying = () => {
    this.setState({ playerStatus: PLAYER_STATUS.playing });
  };

  renderPlayer = () => {
    const { streamId, shouldAutoplay, isLiveStreaming } = this.props;
    const { playerStatus, isHovered, videoPlayerEl } = this.state;
    if (
      !streamId ||
      (!isHovered && !shouldAutoplay) ||
      !videoPlayerEl ||
      !isLiveStreaming
    )
      return null;

    const VideoPlayer = createElement(videoPlayerEl, {
      onSetup: this.handlePlayerSetup,
      source: getLiveSource({ streamId, preset: PRESET_PREVIEW }),
      option: videoPlayerOption,
      shouldCombineTrailer: true,
      onError: this.handlePlayerError,
      onPlaying: this.handlePlayerPlaying,
    });

    return (
      <PlayerWrapper shouldShowPlayer={playerStatus === PLAYER_STATUS.playing}>
        {[PLAYER_STATUS.loading, PLAYER_STATUS.playing].includes(
          playerStatus
        ) && VideoPlayer}
      </PlayerWrapper>
    );
  };

  renderHydrateCover = () => {
    const { streamId, userId, username, meId } = this.props;
    const errorComponent = (
      <Picture width="375" height="667" data-key="error">
        <img
          src={
            CoverPlaceholderImageResource.src || CoverPlaceholderImageResource
          }
          width="375"
          height="667"
          alt="default cover"
        />
      </Picture>
    );
    if (!streamId) {
      return <StatefulImageWrapper>{errorComponent}</StatefulImageWrapper>;
    }

    const userPictureSource = getUserPicture({
      userId,
      meId,
      size: 512,
      format: MediaAssetFormat.JPG,
    }).href;
    return (
      <WithIntersectionObserver threshold={0.2}>
        {({ isIntersecting }) => (
          <HydrationBoundary
            shouldHydrate={isIntersecting}
            wrapper={<StatefulImageWrapper />}
          >
            <StatefulImage>
              <LoadingWrapper data-key="loading">
                <LoadingIcon
                  src={LoadingImageResource.src || LoadingImageResource}
                />
              </LoadingWrapper>
              {errorComponent}
              <Picture data-key="target">
                <DecryptionWrapper resourceUrl={userPictureSource}>
                  {[MediaAssetFormat.AVIF, MediaAssetFormat.WEBP].map(
                    format => (
                      <source
                        key={format}
                        type={`image/${format}`}
                        srcSet={
                          getPublicSrcSet({
                            href: userPictureSource,
                            size: 512,
                            format,
                          }).srcSet
                        }
                      />
                    )
                  )}
                  <img
                    {...getPublicSrcSet({
                      href: userPictureSource,
                      size: 512,
                      format: MediaAssetFormat.JPG,
                    })}
                    alt={`${username}'s cover`}
                  />
                </DecryptionWrapper>
              </Picture>
            </StatefulImage>
          </HydrationBoundary>
        )}
      </WithIntersectionObserver>
    );
  };

  renderCover = () => {
    const { userId, streamId, username, meId } = this.props;
    const imgSrc =
      CoverPlaceholderImageResource.src || CoverPlaceholderImageResource;
    if (!streamId) {
      return (
        <StatefulImageWrapper>
          <LazyImage threshold={0.01}>
            <DecryptionWrapper resourceUrl={imgSrc}>
              <Image src={imgSrc} alt="default cover" />
            </DecryptionWrapper>
          </LazyImage>
        </StatefulImageWrapper>
      );
    }
    const userPictureSource = getUserPicture({
      userId,
      meId,
      size: 512,
      format: MediaAssetFormat.JPG,
    }).href;
    return (
      <StatefulImageWrapper>
        <StatefulImage>
          <LoadingWrapper data-key="loading">
            <LoadingIcon
              src={LoadingImageResource.src || LoadingImageResource}
            />
          </LoadingWrapper>
          <LazyImage data-key="error" threshold={0.01}>
            <DecryptionWrapper resourceUrl={imgSrc}>
              <Image src={imgSrc} alt="default cover" />
            </DecryptionWrapper>
          </LazyImage>
          <LazyImage data-key="target" threshold={0.01}>
            <DecryptionWrapper resourceUrl={userPictureSource}>
              {[MediaAssetFormat.AVIF, MediaAssetFormat.WEBP].map(format => (
                <source
                  key={format}
                  type={`image/${format}`}
                  srcSet={
                    getPublicSrcSet({
                      href: userPictureSource,
                      size: 512,
                      format,
                    }).srcSet
                  }
                />
              ))}
              <Image
                {...getPublicSrcSet({
                  href: userPictureSource,
                  size: 512,
                  format: MediaAssetFormat.JPG,
                })}
                alt={`${username}'s cover`}
              />
            </DecryptionWrapper>
          </LazyImage>
        </StatefulImage>
      </StatefulImageWrapper>
    );
  };

  renderLocation = () => {
    const { t, locationKey, i18n, isJumbo, isLocationKeyTranslationFetched } =
      this.props;
    if (!locationKey) return null;

    const locationPlaceholder = locationKey.replace(/location_/i, '');
    return (
      <LocationWrapper isJumbo={isJumbo}>
        <LocationText isJumbo={isJumbo}>
          {i18n.exists(locationKey, {
            ns: TranslationNamespace.FEED,
          }) && isLocationKeyTranslationFetched
            ? t(locationKey, {
                ns: TranslationNamespace.FEED,
              })
            : locationPlaceholder}
        </LocationText>
      </LocationWrapper>
    );
  };

  renderLivestreamMetadata = () => {
    const {
      userId,
      displayedUsername,
      formattedViewers,
      isJumbo,
      languageBadges,
      isKaraokeDeviceOnline,
      publicBadgeUrlPath,
    } = this.props;

    return (
      <>
        <TopRightWrapper isJumbo={isJumbo}>
          <WithRealTimeStatus userId={userId}>
            {({ isLiveStreaming }) => {
              return (
                isLiveStreaming && (
                  <LiveStreamBadge
                    userId={userId}
                    streamId={userId}
                    type={LIVESTREAM_TYPE_STATUS_BADGE}
                    size={18}
                  />
                )
              );
            }}
          </WithRealTimeStatus>
          <div>
            {languageBadges.map(language => {
              const imgSrc = getResourceUrl({
                path: publicBadgeUrlPath,
                endpoint: `/${language}.png`,
                resourceType: PUBLIC,
              });
              return (
                <DecryptionWrapper key={language} resourceUrl={imgSrc}>
                  <LanguageIcon src={imgSrc} isJumbo={isJumbo} />
                </DecryptionWrapper>
              );
            })}
            {isKaraokeDeviceOnline && (
              <KaraokeDeviceIcon src={IconDeviceSource} alt="Karaoke device" />
            )}
          </div>
        </TopRightWrapper>
        <InformationWrapper>
          <UsernameWrapper isJumbo={isJumbo}>
            <Username
              to={`/user/${userId}`}
              data-element_id={userId && ButtonId.All.ButtonCardAvatar}
              data-tracking_payload={this.getTrackingPayload()}
            >
              {displayedUsername}
            </Username>
          </UsernameWrapper>
          <Viewers>
            <ViewersIcon src={ViewersIconResource} aria-hidden />
            <div>
              <Flip>{formattedViewers}</Flip>
            </div>
          </Viewers>
        </InformationWrapper>
      </>
    );
  };

  renderUserGridMetadata = () => {
    const { userId, formattedViewers, isKaraokeDeviceOnline } = this.props;

    return (
      <>
        <TopLeftWrapper>
          <LivestreamCardUserBadges userId={userId} />
        </TopLeftWrapper>
        <TopRightWrapper>
          {isKaraokeDeviceOnline && (
            <FilledKaraokeDeviceIcon
              src={IconDeviceSource}
              alt="Karaoke device"
            />
          )}
        </TopRightWrapper>
        <RightBottomWrapper></RightBottomWrapper>
        <WithRealTimeStatus userId={userId}>
          {({ isLiveStreaming }) => {
            return (
              isLiveStreaming && (
                <InformationWrapper isOnUserGrid>
                  <Viewers isOnUserGrid>
                    <ViewersIcon src={ViewersIconResource} aria-hidden />
                    <div>
                      <Flip>{formattedViewers}</Flip>
                    </div>
                  </Viewers>
                  <StreamBadge>
                    <LiveStreamBadge
                      userId={userId}
                      streamId={userId}
                      type={LIVESTREAM_TYPE_STATUS_BADGE}
                      size={18}
                      isFluid
                    />
                  </StreamBadge>
                </InformationWrapper>
              )
            );
          }}
        </WithRealTimeStatus>
      </>
    );
  };

  fetchData = () => {
    const {
      i18n,
      username,
      locationKey,
      languageBadges,
      streamId,
      isIntersecting,
      fetchUser,
      fetchTranslationByKey,
    } = this.props;

    if ((!username || !languageBadges.length) && isIntersecting && streamId) {
      fetchUser();
    }

    if (
      locationKey &&
      !i18n.exists(locationKey, { ns: TranslationNamespace.LIVESTREAM })
    ) {
      fetchTranslationByKey({ key: locationKey });
    }
  };

  loadVideoPlayer = () => {
    import('./VideoPlayer.jsx')
      .then(module => module.default)
      .then(VideoPlayer => {
        this.setState({
          videoPlayerEl: VideoPlayer,
        });
      });
  };

  loadVideo = () => {
    const { videoPlayerEl, isHovered } = this.state;
    const { shouldAutoplay } = this.props;

    this.clearSideEffect();

    if (videoPlayerEl || (!shouldAutoplay && !isHovered)) {
      return;
    }

    const delayLoadVideoPlayer = () => {
      this.timeout = setTimeout(() => {
        this.loadVideoPlayer();
      }, DELAY_LOADING_MSEC);
    };

    if (isHovered) {
      this.loadVideoPlayer();
    } else {
      if ('requestIdleCallback' in window) {
        this.idleCallback = window.requestIdleCallback(delayLoadVideoPlayer);
      } else {
        delayLoadVideoPlayer();
      }
    }
  };

  clearSideEffect = () => {
    if ('cancelIdleCallback' in window) {
      window.cancelIdleCallback(this.idleCallback);
      window.cancelIdleCallback(this.fetchRetainedEventsIdleCallback);
    }
    clearTimeout(this.timeout);
  };

  getTrackingPayload = () => {
    const {
      category,
      index,
      userId,
      username,
      streamStatus,
      privateShowStatus,
      formattedViewers,
      isKaraokeDeviceOnline,
      badges,
      countries,
    } = this.props;
    if (!userId) {
      return;
    }

    const goalStatus = [
      privateShowStatusConstant.PREPARING,
      privateShowStatusConstant.PERFORMING,
    ].includes(privateShowStatus)
      ? 'show'
      : [
            privateShowStatusConstant.FUNDING,
            privateShowStatusConstant.FUNDING_FINAL_CALL,
          ].includes(privateShowStatus)
        ? 'show-funding'
        : '';

    let uiStreamStatus = 'public';
    if (streamStatus === 'sd') uiStreamStatus = 'private';
    if (goalStatus) uiStreamStatus = goalStatus;

    const country = countries?.[0] ? countries[0].replace('country:', '') : '';

    return {
      'user.id': userId,
      'user.username': username,
      preset: streamStatus,
      ...(goalStatus && {
        'goal.status': goalStatus,
      }),
      'stream.viewers': formattedViewers,
      'discover.index': index,
      'discover.category': category,
      'ui.lovense.status': isKaraokeDeviceOnline ? 'on' : 'off',
      'ui.stream.status': uiStreamStatus,
      'ui.badges': badges?.[0] ?? '',
      'ui.countries': country,
    };
  };

  componentDidMount() {
    this.fetchData();
    this.loadVideo();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      streamId: prevStreamId,
      isAuthed: prevIsAuthed,
      isIntersecting: prevIsIntersecting,
      shouldAutoplay: prevShouldAutoplay,
      locationKey: prevLocationKey,
      fetchLiveStreamAvailabilityId: prevFetchLiveStreamAvailabilityId,
    } = prevProps;
    const {
      streamId,
      isAuthed,
      isIntersecting,
      shouldAutoplay,
      locationKey,
      fetchLiveStreamAvailabilityId,
      isLiveStreaming,
      userId,
      push,
    } = this.props;
    const { isHovered } = this.state;
    const { isHovered: prevIsHovered } = prevState;

    if (
      prevIsAuthed !== isAuthed ||
      prevIsIntersecting !== isIntersecting ||
      streamId !== prevStreamId ||
      locationKey !== prevLocationKey
    ) {
      this.fetchData();
    }

    if (prevShouldAutoplay !== shouldAutoplay) {
      this.loadVideo();
      this.setState({
        isHovered: false,
        playerStatus: shouldAutoplay
          ? PLAYER_STATUS.loading
          : PLAYER_STATUS.idle,
      });
    }

    if (isHovered !== prevIsHovered) {
      this.loadVideo();
    }

    if (
      // It indicates that the fetchLiveStreamAvailability action has finished.
      prevFetchLiveStreamAvailabilityId !== fetchLiveStreamAvailabilityId &&
      // It suggests that there is no other actions take precedence. (since we only keep the latest request)
      _latestFetchLiveStreamAvailabilityId === fetchLiveStreamAvailabilityId &&
      fetchLiveStreamAvailabilityId
    ) {
      _latestFetchLiveStreamAvailabilityId = undefined;
      this.setState(
        {
          shouldShowLoadingOnCheckingStream: false,
        },
        () => {
          let search = '';

          if (!isServer) {
            const searchParams = new URLSearchParams(location.search);
            const [uiQuery] = searchParams.getAll('ui');
            if (uiQuery && !UiQueryWhiteList[uiQuery])
              searchParams.delete('ui');
            if (searchParams.size) search = `?${searchParams.toString()}}`;
          }

          // Although `isLiveStreaming` could be updated by polling mechanism or API response,
          // we check if `fetchLiveStreamAvailabilityId` is updated first and
          // that ensures that the current `isLiveStreaming` is updated via API.
          if (isLiveStreaming) {
            // Navigate to live stream room if it's streaming
            push({ pathname: `/user/${userId}/${LIVESTREAM_PATH}${search}` });
          } else {
            // Navigate to user profile when
            // either it's not streaming or the API has timed out.
            push({ pathname: `/user/${userId}${search}` });
          }
        }
      );
    }
  }

  componentWillUnmount() {
    _latestFetchLiveStreamAvailabilityId = undefined;
    this.clearSideEffect();
  }

  render() {
    const {
      userId,
      username,
      isJumbo,
      disableHoverEffect,
      disableLink,
      disableOnMouseLeave,
      isOnUserGrid,
      isLiveStreaming,
      liveStreamFeed,
      category,
    } = this.props;
    const { shouldShowLoadingOnCheckingStream } = this.state;
    const shouldHydrate = isServer || !window.__IS_HYDRATED__;
    const renderCover = shouldHydrate
      ? this.renderHydrateCover
      : this.renderCover;
    const isLiveStreamFeed = isAllAliasesInTargetCategory({
      probeCategory: liveStreamFeed,
      targetCategory: category,
    });
    const isLiveStreamingUndetermined =
      !isLiveStreamFeed && isOnUserGrid && isLiveStreaming == undefined;
    const isEnteringLiveStreamRoom =
      isLiveStreamFeed || isLiveStreaming || !isOnUserGrid;

    return (
      <WithIntersectionObserver
        threshold={0.2}
        onChange={this.handleInViewport}
        shouldKeepObserve
      >
        {({ isIntersecting }) =>
          isIntersecting ? (
            <StyledLiveStreamCard
              onMouseEnter={
                disableHoverEffect ? undefined : this.handleOnMouseEnter
              }
              onMouseLeave={
                disableHoverEffect || disableOnMouseLeave
                  ? undefined
                  : this.handleOnMouseLeave
              }
              isJumbo={isJumbo}
            >
              {renderCover()}
              {this.renderPlayer()}
              {this.renderLocation()}
              <Overlay isJumbo={isJumbo}>
                <UserProfileLink
                  onClick={
                    isLiveStreamingUndetermined
                      ? this.handleOnProfileLinkClick
                      : undefined
                  }
                  to={
                    isEnteringLiveStreamRoom
                      ? `/user/${userId}/${LIVESTREAM_PATH}${
                          isServer ? '' : location.search
                        }`
                      : `/user/${userId}${isServer ? '' : location.search}`
                  }
                  aria-label={
                    isEnteringLiveStreamRoom
                      ? `${username}'s livestream room`
                      : `${username}'s profile`
                  }
                  data-element_id={userId && ButtonId.All.ButtonLiveStreamCard}
                  data-tracking_payload={this.getTrackingPayload()}
                  as={!userId || disableLink ? undefined : Link}
                >
                  {shouldShowLoadingOnCheckingStream && (
                    <StatusLoadingWrapper data-key="loading">
                      <Loading type={LoadingType.GRAY} />
                    </StatusLoadingWrapper>
                  )}
                </UserProfileLink>
              </Overlay>
              {isOnUserGrid
                ? this.renderUserGridMetadata()
                : this.renderLivestreamMetadata()}
            </StyledLiveStreamCard>
          ) : (
            <StyledLiveStreamCard />
          )
        }
      </WithIntersectionObserver>
    );
  }
}

LiveStreamCard.propTypes = {
  fetchLiveStreamAvailabilityId: PropTypes.string,
  category: PropTypes.string,
  index: PropTypes.number,
  i18n: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  meId: PropTypes.string,
  userId: PropTypes.string,
  streamId: PropTypes.string,
  username: PropTypes.string,
  displayedUsername: PropTypes.string,
  locationKey: PropTypes.string,
  formattedViewers: PropTypes.string,
  publicBadgeUrlPath: PropTypes.string,
  liveStreamFeed: PropTypes.string,
  streamStatus: PropTypes.string,
  privateShowStatus: PropTypes.string,
  isJumbo: PropTypes.bool,
  disableHoverEffect: PropTypes.bool,
  disableOnMouseLeave: PropTypes.bool,
  disableLink: PropTypes.bool,
  isAuthed: PropTypes.bool,
  isIntersecting: PropTypes.bool,
  shouldAutoplay: PropTypes.bool,
  isLocationKeyTranslationFetched: PropTypes.bool,
  isLiveStreaming: PropTypes.bool,
  isKaraokeDeviceOnline: PropTypes.bool,
  isOnUserGrid: PropTypes.bool,
  languageBadges: PropTypes.array,
  push: PropTypes.func,
  fetchLiveStreamAvailability: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchTranslationByKey: PropTypes.func,
  fetchRetainedEvents: PropTypes.func,
  badges: PropTypes.array,
  countries: PropTypes.array,
};

LiveStreamCard.defaultProps = {
  fetchLiveStreamAvailabilityId: '',
  category: null,
  index: null,
  meId: null,
  userId: null,
  streamId: null,
  username: null,
  displayedUsername: null,
  formattedViewers: '-',
  locationKey: '',
  publicBadgeUrlPath: '',
  liveStreamFeed: '',
  streamStatus: '',
  privateShowStatus: '',
  isAuthed: false,
  isJumbo: false,
  disableHoverEffect: isMobile,
  disableOnMouseLeave: false,
  disableLink: false,
  isIntersecting: false,
  shouldAutoplay: false,
  isLocationKeyTranslationFetched: false,
  isLiveStreaming: undefined,
  isKaraokeDeviceOnline: false,
  isOnUserGrid: false,
  languageBadges: EMPTY_ARRAY,
  push: NULL_FUNCTION,
  fetchLiveStreamAvailability: NULL_FUNCTION,
  fetchUser: NULL_FUNCTION,
  fetchTranslationByKey: NULL_FUNCTION,
  fetchRetainedEvents: NULL_FUNCTION,
  badges: EMPTY_ARRAY,
  countries: EMPTY_ARRAY,
};

const StyledLiveStreamCard = styled.div`
  position: relative;
  border-radius: 4px;
  padding-top: 100%;
  overflow: hidden;
  display: block;
  :hover {
    opacity: 1;
  }
  @media (min-width: ${breakpoint.tablet}px) {
    ${({ isJumbo }) => {
      if (isJumbo) {
        return css`
          border-radius: 0px;
        `;
      }
    }}
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-radius: 4px;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 0.04) 25%,
    rgba(0, 0, 0, 0.04) 33%,
    rgba(0, 0, 0, 0.5)
  );
  @media (min-width: ${breakpoint.tablet}px) {
    ${({ isJumbo }) => {
      if (isJumbo) {
        return css`
          border-radius: 0px;
        `;
      }
    }}
  }
`;

const UserProfileLink = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none;
  user-select: none;
  user-drag: none;
`;

const TopRightWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  @media (min-width: ${breakpoint.mobile}px) {
    top: ${({ isJumbo }) => isJumbo && '12px'};
    right: ${({ isJumbo }) => isJumbo && '12px'};
  }
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const InformationWrapper = styled.div`
  position: absolute;
  ${({ isOnUserGrid }) =>
    isOnUserGrid
      ? css`
          left: 0px;
          right: 0px;
          bottom: 0px;
        `
      : css`
          left: 4px;
          right: 4px;
          bottom: 4px;
        `};
`;

const UsernameWrapper = styled.div`
  font-size: ${({ isJumbo }) => (isJumbo ? '18px' : '12px')};
  font-weight: 600;
  color: ${textColor.white};
`;

const Username = styled(Link)``;

const Viewers = styled.div.attrs(({ isOnUserGrid }) => ({
  style: {
    marginBottom: `${isOnUserGrid ? 4 : 0}px`,
    paddingLeft: isOnUserGrid ? '4px' : '0px',
    paddingRight: isOnUserGrid ? '4px' : '0px',
  },
}))`
  display: flex;
  align-items: center;
  color: ${color.neutral[100]};
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.024px;
  ${media.tablet`
    font-size: 12px;
  `}
`;

const StatefulImageWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

const Picture = styled.picture`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: ${loadingIconOpacity};
`;

const LoadingIcon = styled.img`
  width: 32px;
  height: auto;
`;

const PlayerWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  video {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    object-fit: cover;
    transition: opacity 0.4s;
    ${({ shouldShowPlayer }) => `opacity: ${shouldShowPlayer ? 1 : 0};`}
    ${media.mobile`
      border-radius: 4px;
    `}
  }
`;

const StatusLoadingWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: var(--number-neutral-4, 4px);
  background: var(
    --overlay-overlay-image-color-tertiary-default,
    rgba(25, 25, 25, 0.8)
  );
`;

const LanguageIcon = styled.img.attrs(({ isJumbo }) => ({
  style: {
    width: isJumbo ? '20px' : '16px',
  },
}))`
  margin-top: 5px;
  margin-right: 4px;
  height: auto;
  :last-child {
    margin-right: 0px;
  }
  filter: drop-shadow(0 0 4px rgb(0 0 0 / 50%));
`;

const LocationWrapper = styled.div`
  max-width: 45%;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 1;
  bottom: 8px;
  right: 8px;
  @media (min-width: ${breakpoint.mobile}px) {
    bottom: ${({ isJumbo }) => isJumbo && '12px'};
    right: ${({ isJumbo }) => isJumbo && '12px'};
  }
`;

const LocationText = styled.div`
  font-size: ${({ isJumbo }) => (isJumbo ? '14px' : '12px')};
  line-height: 1;
  color: #fff;
  ${TextEllipsis}
`;

const ViewersIcon = styled.img`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  ${media.tablet`
    width: 12px;
    height: 12px;
  `}
`;

const RightBottomWrapper = styled.div`
  position: absolute;
  right: 4px;
  bottom: 4px;
`;

const KaraokeDeviceIcon = styled.img`
  border-radius: 50%;
  padding: 6px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tablet`
    padding: 4px;
    width: 28px;
    height: 28px;
  `}
`;

const FilledKaraokeDeviceIcon = styled(KaraokeDeviceIcon)`
  background-color: ${color.lovense};
`;

const TopLeftWrapper = styled.div`
  position: absolute;
  left: 4px;
  top: 4px;
`;

const StreamBadge = styled.div`
  width: 100%;
  height: 24px;
  ${media.tablet`
    height: 18px;
  `};
`;

export default withTranslation()(withViewportItemTracker(LiveStreamCard));
