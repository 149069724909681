// WithRealTimeStatus.js
import { connect } from 'react-redux';
import toggleSubscribeOnlineStatus from '../action/toggleSubscribeOnlineStatus.js';
import getUserData from '../selector/getUserData.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';
import WithRealTimeStatus from '../component/WithRealTimeStatus.jsx';

const mapStateToProps = (state, { userId }) => {
  const isOnline = userId ? getUserData(state, userId, 'isOnline') : false;
  const replyPrice = userId ? getUserData(state, userId, 'replyPrice') : null;
  const isLiveStreaming = userId
    ? getLiveStreamData(state, [userId], 'isLiveStreaming')
    : false;
  return {
    isOnline,
    replyPrice,
    isLiveStreaming,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    toggleSubscribeOnlineStatus: ({
      userId,
      shouldSubscribe,
      subscriptionReference,
    }) =>
      dispatch(
        toggleSubscribeOnlineStatus({
          userId,
          shouldSubscribe,
          subscriptionReference,
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRealTimeStatus);
